import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const languages = [
  { code: "en", lang: "English" },
  { code: "it", lang: "Italian" }
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    const supportedLang = languages.find(lang => lang.code === browserLang);
    if (supportedLang) {
      changeLanguage(supportedLang.code);
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setHovered(false);
    
    const currentPath = location.pathname;
    let newPath;
    if (lng === "en") {
      newPath = currentPath.replace(/^\/it/, "");
    } else if (lng === "it") {
      newPath = currentPath.startsWith("/it") ? currentPath : `/it${currentPath}`;
    }
    
    navigate(newPath, { replace: true });
  };

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div className="relative z-40">
      <button
        className="focus:outline-none flex items-center gap-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FontAwesomeIcon icon={faGlobe} className="mr-2" />
        {languages.find((lng) => lng.code === i18n.language)?.lang}
      </button>
      <div
        className={`absolute top-full bg-enoOrange-200 right-0 border border-none rounded shadow p-2 z-40 transition-all duration-500 ease-in-out transform ${hovered ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2 pointer-events-none"}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {languages.filter(lng => lng.code !== i18n.language).map((lng) => (
          <div
            key={lng.code}
            className="w-full text-left hover:bg-enoDark py-1 px-2 cursor-pointer"
            onClick={() => changeLanguage(lng.code)}
          >
            {lng.lang}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;