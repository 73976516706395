import React, {  useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls } from '@react-three/drei';
import { Bloom, DepthOfField, EffectComposer, Noise } from '@react-three/postprocessing'
import { KernelSize, Resolution } from 'postprocessing';
import * as THREE from 'three';
import { Model } from '../public/Official-enotorre-disk2';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Scene = ({ isPaused, enableEffects, setEnableEffects }) => {
  return (
    <>
      {enableEffects && (
       <>
        <DepthOfField focusDistance={0.15} focalLength={0.08} bokehScale={4} height={480} />
        <Noise opacity={0.05} />
        <Bloom
            intensity={0.3} // The bloom intensit
            kernelSize={KernelSize.VERY_LARGE} // blur kernel size
            luminanceThreshold={1.5} // luminance threshold. Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            mipmapBlur={false} // Enables or disables mipmap blur.
            resolutionX={Resolution.AUTO_SIZE} // The horizontal resolution.
            resolutionY={Resolution.AUTO_SIZE} // The vertical resolution.
        />
        </>
      )}
      <directionalLight position={[0, 4, 2]} castShadow intensity={1} />
      <OrbitControls 
        enablePan={false} 
        maxPolarAngle={1.8} 
        enableZoom={true} 
        minDistance={4} 
        maxDistance={10} 
        enableDamping 
        dampingFactor={0.05} 
      />
      <Model enableEffects={enableEffects} setEnableEffects={setEnableEffects} />
     
      <Environment preset='warehouse' />
    </>
  );
};

const Experience = () => {
  
  const { t } = useTranslation("global");
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const [isPaused, setIsPaused] = useState(!inView);
  const [enableEffects, setEnableEffects] = useState(false);

  useEffect(() => {
    setIsPaused(!inView);
  }, [inView]);

  return (
    <div ref={ref} className="h-full w-full">
      <Canvas
        gl={{
            antialias: true,
            toneMapping: THREE.ReinhardToneMapping,
            outputColorSpace: THREE.LinearSRGBColorSpace
    
        }}
        camera={{
          fov: 45,
          near: 0.1,
          far: 50,
          position: [0, 0, 8],
        }}
        shadows
        dpr={[1, 2]}
      >
        <EffectComposer >
          <Scene isPaused={isPaused} enableEffects={enableEffects} setEnableEffects={setEnableEffects} />
        </EffectComposer>
      </Canvas>
     
    </div>
  );
};

export default Experience;




/* 

home 
fare enotorre records+ grande con logo immagine 


*/

/* 

Modello cd -> hover offste strano


*/


/* 

Press release -> tenere l'immagine
fare in modo di scaricare file doc del comunicato stampa 

Dicono di noi -> togliere da press release e mettere qua i link comunicato stampa 

*/

