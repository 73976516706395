import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

function PressRelease() {
    const { t } = useTranslation("global");

    return <div className="h-[calc(100dvh)] bg-enoDark   rounded-none md:rounded-lg relative snap-start flex flex-col md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-8">

        <img src='/copertina.webp' alt='copertina disco edizione 1 enotorrerecords' className='w-full  md:w-2/5 hover:scale-110 hover:shadow-3xl transition-transform duration-300 ease-in-out
        hover:shadow-white rounded-lg hover:rotate-2'></img>
        <div className="flex flex-col justify-center items-start gap-2">
        <h2 className="font-sans text-white text-xl sm:text-2xl md:text-3xl mb-2">
        <span className="">{t('press.titlePress')}</span>
        </h2>
        
        <div className="max-w-3xl flex flex-col">
          
            <a
              href="../files/comunicato_stampa.docx"
              download="comunicato_stampa.docx"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white pb-2 text-left hover:underline
               text-enoOrange-200 transition-colors text-xl duration-700 transitio-border-color 
                ease-in-out w-full sm:w-auto"  >
                  
                  <FontAwesomeIcon icon={faFile} className='mr-2'/>
              {t('press.btnPress')}
            </a>
            <a
              href="../files/cartella_stampa.docx"
              download="cartella_stampa.docx"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white pb-2 text-left hover:underline
               text-enoOrange-200 transition-colors text-xl duration-700 transitio-border-color 
                ease-in-out w-full sm:w-auto">
                  <FontAwesomeIcon icon={faFolder} className='mr-2'/>
              {t('press.btnPress2')}
            </a>
        </div>
        </div>
    </div>
      
}

export default PressRelease;