
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../App';

const Footer = () => {
  const { t } = useTranslation("global");


  return (
     
<footer className=" bg-[#303030] h-auto p-6 text-white place-content-center snap-end gap-4 sm:p-6 font-serif text-center md:p-12 lg:p-16">
          <div className="mx-auto flex flex-col md:flex-row justify-center items-center">
            <div className="flex flex-col items-center">
                <a href='/' className='text-2xl mb-10 font-semibold text-white-200'>ENOTORRERECORDS</a>
                <p>L'ENOTORRE S.A.S. DI ANTONIO POSTIGLIONE & C</p>
                <p>VIA DI MEZZO 2, 33097 Spilimbergo PN ITALIA</p>
                <p>P.IVA 00632760930 | info@enotorrerecords.com</p>
            </div>
          
            
          </div>
          <div className="flex p-4 md:mt-0 flex-col mt-2 justify-center gap-2 items-center">
          <p className="text-sm mt-5 text-white">
                © 2024 LIVE<span className="text-zucsGreen-200"><strong>AT</strong></span>
                ENOTECALATORRE. {t('home.rights')}
              </p>
            <NavLink to="/privacy" className="hover:text-enoOrange-200 transition-colors duration-300">
                          {t('Privacy Policy')}
                        </NavLink>
              
            </div>
          
        </footer>
  );
};

export default Footer;