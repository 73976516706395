import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Experience from '../Experience';
import { NavLink } from '../App';

function Contacts() {
    const { t } = useTranslation("global");

    return <div id='contact' className="m-0  h-[calc(100dvh)]  rounded-none md:rounded-lg snap-center bg-enoDark 
    flex flex-col md:flex-row items-center gap-6 justify-center p-0 ">
    
    <div className={`relative h-[100vh] w-full`}>
    
    <Experience />
     
    </div>
    
    <div className="w-full md:w-1/2 p-4 h-[50vh] sm:h-[40vh] flex flex-col-reverse gap-2 items-start">
    <NavLink to="/" 
            className="block mt-1 border-white border-solid  text-right w-full text-xl hover:text-enoOrange-200 text-white transition-colors duration-300" >
                 <FontAwesomeIcon icon={faHome} className=' mr-2'/>
                 {t('Torna su home')}
               </NavLink>
      <h2 className="font-sans text-zucsGreen-200 text-xl sm:text-xl md:text-5xl lg:text-6xl uppercase mb-1">
      <span className="bg-white text-enoOrange-200 pt-2 md:pt-0 px-2">{t('home.fTitle')}</span>
      </h2>
     
    
         <a className='text-white flex flex-row hover:text-enoOrange-200 transition-colors duration-700 font-serif text-xl items-center gap-3' href="mailto:info@enotorrerecords.com">
         <FontAwesomeIcon icon={faEnvelope}
          className="text-4xl  sm:text-5xl align-top md:text-6xl "
        />info@enotorrerecords.com</a>
        <a className='text-white hover:text-green-600 transition-colors duration-700 flex flex-row  font-serif text-xl items-center gap-3' target='_blank' href="https://wa.me/393886064901?text=I'm%20interested%20in%20purchasing%20the%20CD%20from%20Enotorrerecords.com">
        <FontAwesomeIcon icon={faWhatsapp}
        className="text-4xl sm:text-5xl  align-top md:text-6xl "
      />+39 388 606 4901</a>
          
       
      
    </div>
    
    </div>
}

export default Contacts;