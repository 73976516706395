import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export function SEO({ page }) {
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  const lang = i18n.language;
  const isItalian = lang === 'it';

  const baseUrl = "https://www.enotorrerecords.com";
  const currentPath = location.pathname;
  
  const getCanonicalUrl = () => {
    if (isItalian) {
      return `${baseUrl}/it${currentPath.replace('/it', '')}`;
    }
    return `${baseUrl}${currentPath}`;
  };

  const getAlternateUrl = (targetLang) => {
    if (targetLang === 'it') {
      return `${baseUrl}/it${currentPath.replace('/it', '')}`;
    }
    return `${baseUrl}${currentPath.replace('/it', '')}`;
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t(`seo.${page}.title`)}</title>
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Enotorre Records" />
      <meta name="publisher" content="Enotorre Records" />
      <meta name="description" content={t(`seo.${page}.description`)} />
      <meta name="keywords" content={t(`seo.${page}.keywords`)} />
      <link rel="alternate" hreflang="it" href={getAlternateUrl('it')} />
      <link rel="alternate" hreflang="en" href={getAlternateUrl('en')} />
      <link rel="canonical" href={getCanonicalUrl()} />
    </Helmet>
  );
}